import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./skin/Header";
import Intro from "./routes/intro/Intro";
import Login from "./routes/login/Login";
import JoinStep_1 from "./routes/join/JoinStep_1";
import JoinStep_2 from "./routes/join/JoinStep_2";
import JoinCreate from "./routes/join/JoinCreate";
import JoinCreateConfirm from "./routes/join/JoinCreateConfirm";
import JoinCreateHold from "./routes/join/JoinCreateHold";
import JoinRegistration from "./routes/join/JoinRegistration";
import FindPw from "./routes/find/FindPw";
import ChangePw from "./routes/find/ChangePw";
import MyPage from "./routes/mypage/MyPage";
import Home from "./routes/home/Home";
import Notification from "./routes/notification/Notification";
import Homework from "./routes/homework/Homework";
import Material from "./routes/material/Material";
import Student from "./routes/student/Student";
import Academy from "./routes/academy/Academy";
import AcademySchedule from "./routes/academy/academy_schedule/AcademySchedule";
import AcademyScheduleType from "./routes/academy/academy_schedule/AcademyScheduleType";
import AcademyScheduleAdd from "./routes/academy/academy_schedule/AcademyScheduleAdd";
import AcademyScheduleDetail from "./routes/academy/academy_schedule/AcademyScheduleDetail";
import AcademyScheduleEdit from "./routes/academy/academy_schedule/AcademyScheduleEdit";
import AcademyShuttle from "./routes/academy/academy_shuttle/AcademyShuttle";
import AcademyShuttleType from "./routes/academy/academy_shuttle/AcademyShuttleType";
import AcademyShuttleAdd from "./routes/academy/academy_shuttle/AcademyShuttleAdd";
import AcademyShuttleDetail from "./routes/academy/academy_shuttle/AcademyShuttleDetail";
import AcademyShuttleEdit from "./routes/academy/academy_shuttle/AcademyShuttleEdit";
import AcademyEvaluation from "./routes/academy/academy_evaluation/AcademyEvaluation";
import AcademyEvaluationDetail from "./routes/academy/academy_evaluation/AcademyEvaluationDetail";
import AcademyClassroom from "./routes/academy/academy_classroom/AcademyClassrom";
import AcademyClassroomDetail from "./routes/academy/academy_classroom/AcademyClassroomDetail";
import AcademyClassroomEdit from "./routes/academy/academy_classroom/AcademyClassroomEdit";
import AcademyClassroomAdd from "./routes/academy/academy_classroom/AcademyClassroomAdd";
import AcademyTeachers from "./routes/academy/academy_teachers/AcademyTeachers";
import AcademyTeachersDetail from "routes/academy/academy_teachers/AcademyTeachersDetail";
import AcademyInfo from "./routes/academy/academy_info/AcademyInfo";
import AcademyInfoEdit from "./routes/academy/academy_info/AcademyInfoEdit";
import AcademyRetireeTeachers from "routes/academy/academy_teachers/AcademyRetireeTeachers";
import AcademyPayment from "routes/academy/academy_payment/AcademyPayment";
import StudentAdd from "routes/student/StudentAdd";
import StudentDetail from "routes/student/StudentDetail";
import StudentEdit from "routes/student/StudentEdit";
import StudentSelectClass from "routes/student/StudentSelectClass";
import StudentSelectShuttle from "routes/student/StudentSelectShuttle";
import NotificationDetail from "routes/notification/NotificationDetail";
import HomeworkDetail from "routes/homework/HomeworkDetail";
import MaterialDetail from "routes/material/MaterialDetail";
import MaterialAdd from "routes/material/MaterialAdd";
import MaterialEdit from "routes/material/MaterialEdit";
import HomeworkAdd from "routes/homework/HomeworkAdd";
import HomeworkEdit from "routes/homework/HomeworkEdit";
import NotificationAdd from "routes/notification/NotificationAdd";
import NotificationEdit from "routes/notification/NotificationEdit";
import Message from "components/modal/Message";
import ContactUsPage from "routes/contactUs/ContactUsPage";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { headerTitleAtom } from "atoms/atoms";
import createChannelIO from "utils/createChannelIO";
import bootChannelIO from "utils/bootChannelIO";
import setVh from "utils/setVh";

function App() {
  const headerTitle = useSetRecoilState(headerTitleAtom);
  const { pathname } = useLocation();

  //App.js
  useEffect(() => {
    //채널톡 생성
    createChannelIO();

    //채널톡 실행(웹사이트 접속 시 최초 부팅)
    bootChannelIO();
  }, []);

  useEffect(() => {
    //사파리 접속 시 도구막대 제외한 뷰포트 계산
    // window.addEventListener("resize", setVh);
    setVh();
    headerTitle(
      document.querySelector(".inner header h2")?.innerText.split("(")[0]
    );
  }, [pathname]);

  return (
    <div className="background">
      <Header />
      <Message />
      <Routes>
        <Route path="/" element={<Intro />} />
        <Route path="/qna" element={<Intro />} />
        <Route path="/login" element={<Login />} />

        <Route path="/auth/join/step1" element={<JoinStep_1 />} />
        <Route path="/auth/join/step2" element={<JoinStep_2 />} />
        <Route path="/auth/join/sign-up" element={<JoinCreate />} />
        <Route path="/auth/join/complete" element={<JoinCreateConfirm />} />
        <Route path="/auth/join/hold" element={<JoinCreateHold />} />
        <Route path="/auth/find-pw" element={<FindPw />} />
        <Route path="/auth/change-pw" element={<ChangePw />} />
        
        <Route path="/join/invitee" element={<JoinRegistration />} />

        <Route path="/mypage" element={<MyPage />} />

        <Route path="/home" element={<Home />} />

        <Route path="/notification/:id" element={<Notification />} />
        <Route
          path="/notification/detail/:id"
          element={<NotificationDetail />}
        />
        <Route path="/notification/add" element={<NotificationAdd />} />
        <Route path="/notification/edit/:id" element={<NotificationEdit />} />

        <Route path="/homework/:id" element={<Homework />} />
        <Route path="/homework/detail/:id" element={<HomeworkDetail />} />
        <Route path="/homework/add" element={<HomeworkAdd />} />
        <Route path="/homework/edit/:id" element={<HomeworkEdit />} />

        <Route path="/material/:id" element={<Material />} />
        <Route path="/material/detail/:id" element={<MaterialDetail />} />
        <Route path="/material/add" element={<MaterialAdd />} />
        <Route path="/material/edit/:id" element={<MaterialEdit />} />

        <Route path="/student/:id" element={<Student />} />
        <Route path="/student/add" element={<StudentAdd />}>
          <Route path="select-class" element={<StudentSelectClass />} />
          <Route path="select-shuttle" element={<StudentSelectShuttle />} />
        </Route>
        <Route path="/student/detail/:id" element={<StudentDetail />} />
        <Route path="/student/edit/:id" element={<StudentEdit />}>
          <Route path="select-class" element={<StudentSelectClass />} />
          <Route path="select-shuttle" element={<StudentSelectShuttle />} />
        </Route>

        <Route path="/academy" element={<Academy />}>
          <Route path="info" element={<AcademyInfo />} />
          <Route path="info/edit" element={<AcademyInfoEdit />} />

          <Route path="schedule/:id" element={<AcademySchedule />} />
          <Route path="schedule/type" element={<AcademyScheduleType />} />
          <Route path="schedule/add/:id" element={<AcademyScheduleAdd />} />
          <Route
            path="schedule/detail/:id"
            element={<AcademyScheduleDetail />}
          />
          <Route path="schedule/edit/:id" element={<AcademyScheduleEdit />} />

          <Route path="shuttle/:id" element={<AcademyShuttle />} />
          <Route path="shuttle/type" element={<AcademyShuttleType />} />
          <Route path="shuttle/add/:id" element={<AcademyShuttleAdd />} />
          <Route path="shuttle/detail/:id" element={<AcademyShuttleDetail />} />
          <Route path="shuttle/edit/:id" element={<AcademyShuttleEdit />} />

          <Route path="evaluation/:id" element={<AcademyEvaluation />} />
          <Route
            path="evaluation/detail/:id"
            element={<AcademyEvaluationDetail />}
          />

          <Route path="classroom/:id" element={<AcademyClassroom />} />
          <Route path="classroom/add" element={<AcademyClassroomAdd />} />
          <Route path="classroom/edit/:id" element={<AcademyClassroomEdit />} />
          <Route
            path="classroom/detail/:id"
            element={<AcademyClassroomDetail />}
          />

          <Route path="teachers/:id" element={<AcademyTeachers />} />
          <Route
            path="teachers/detail/:id"
            element={<AcademyTeachersDetail />}
          />

          <Route path="retiree/:id" element={<AcademyRetireeTeachers />} />

          <Route path="payment/:id" element={<AcademyPayment />} />
        </Route>

        <Route path="/contact-us" element={<ContactUsPage />} />
      </Routes>
    </div>
  );
}

export default App;
